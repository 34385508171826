/* eslint-disable camelcase */

import React, { useState } from 'react'
import { Alert, Button, Form, Modal } from 'react-bootstrap'
import AlertDynamic from '../../../components/AlertDynamic'
import { CreateSelect } from '../../../components/Form'
import Spinner from '../../../components/spinner'
import { NOOP } from '../../../components/utils'
import { useInviteMemberToCompany } from '../../../utils/api'
/**
 *
 * !!! Page !!!
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const MyAccountInviteUserModal = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP } = props

  // states
  const [userToInvite, setUserToInvite] = useState({
    role: '',
    email: ''
  })
  const [emailInvaild, setEmailInvalid] = useState(false)
  // update keys for dynamic alerts
  const [update, setUpdate] = React.useState(0)
  // functions

  // react-query api
  const InviteMemberToCompany = useInviteMemberToCompany()
  const validateEmail = (email) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
  }
  const onKeyDown = (e) => {
    if (e.keyCode === 13) {
      setUpdate(update + 1)
      const submitInfo = {
        role: userToInvite.role,
        email: userToInvite.email
      }
      if (validateEmail(submitInfo.email)) {
        setEmailInvalid(false)
        InviteMemberToCompany.mutate({ invitedMember: submitInfo })
      } else {
        setEmailInvalid(true)
      }
    }
  }
  const onHide = () => {
    setOpenModal(false)
    setEmailInvalid(false)
    InviteMemberToCompany.reset()
    setUserToInvite({
      role: '',
      email: ''
    })
  }
  const inviteNewCompany = (e) => {
    e.preventDefault()
    setUpdate(update + 1)
    setEmailInvalid(false)
    if (userToInvite.role.length !== 0 && userToInvite.email.length !== 0) {
      const submitInfo = {
        role: userToInvite.role,
        email: userToInvite.email
      }
      if (validateEmail(submitInfo.email)) {
        setEmailInvalid(false)
        InviteMemberToCompany.mutate({ invitedMember: submitInfo })
      } else {
        setEmailInvalid(true)
      }
    }
  }

  return (
    <>
      <Modal show={openModal} onHide={onHide} >
        <Modal.Body>
          <button type="button" className="close" onClick={onHide}>
            <span aria-hidden="true">×</span>
            <span className="sr-only">Close</span>
          </button>
          <h5 className="m-0 mb-3">Invite user</h5>
          <p>Invite a user to join the team.</p>

          <form>
            <Form.Group>
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="email"
                autoComplete="email"
                placeholder="Email address"
                autoFocus={true}
                name="company-mail"
                onKeyDown={onKeyDown}

                value={userToInvite.email}
                onChange={(e) =>
                  setUserToInvite((ps) => ({
                    ...ps,
                    email: e.target.value
                  }))
                }
              />
            </Form.Group>

            <CreateSelect
            label="Role"
            required
            // onChange={handleChange('country')}
            value={userToInvite.role}
            placeholder="Select a role"
            className="custom-input-grey"
            onChange={(e) =>
              setUserToInvite((ps) => ({
                ...ps,
                role: e.target.value
              }))
              }

            options={[
              { value: 'admin', label: 'Admin' },
              { value: 'member', label: 'Member' },
              { value: 'readOnly', label: 'Read Only' }
            ]}
          />
          </form>

          {/* buttons */}
          <div className="d-flex justify-content-between mt-5">
            <Button
              type="button"
              onClick={onHide}
              variant="outline-primary"
              style={{ border: 0 + 'px' }}
            >
              {InviteMemberToCompany.isSuccess ? 'Close' : 'Cancel'}
            </Button>
            <Button
              type="button"
              onClick={inviteNewCompany}
              variant="primary"
              disabled={
                userToInvite.role.length === 0 ||
                userToInvite.email.length === 0 ||
                InviteMemberToCompany.isSuccess
              }
            >
              {InviteMemberToCompany.isLoading ? <Spinner /> : <span>Invite</span>}
            </Button>
          </div>
          {emailInvaild && (
          <AlertDynamic key={update} msg={'Email invalid'}></AlertDynamic>
          )}

          {InviteMemberToCompany.isSuccess && (
            <Alert variant="success" className="mb-0 mt-3 text-center">
              Invite successful!
            </Alert>
          )}
          {InviteMemberToCompany.isError && (
            <Alert variant="danger" className="mb-0 mt-3 text-center">
              {'Error happens when invite: ' + InviteMemberToCompany.error.message}
            </Alert>
          )}
          {/* { process.env.NODE_ENV !== 'production' && <Button className="mt-3" onClick={() => clearFile()}>Clear File</Button>} */}
        </Modal.Body>
        {/* {process.env.NODE_ENV !== 'production' && (<pre>companiesQuery={JSON.stringify(userToInvite, null, 2)}</pre>)} */}
      </Modal>

      {/* {process.env.NODE_ENV !== 'production' && <pre>listingLotSkus={JSON.stringify(listingLotSkus, null, 2)}</pre>} */}
    </>
  )
}

export default MyAccountInviteUserModal
